import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import AvatarLinks from "../components/Avatar/AvatarLinks";
import { useColorMode } from "theme-ui";

import J2021_IEEERobo_abs from "../../static/publications/J2021_IEEERobo_abs.gif";
import J2021_JFM_abs from "../../static/publications/J2021_JFM_abs.jpg";
import J2021_CM_abs from "../../static/publications/J2021_CM_abs.gif";
import C2021_IEEEAero_abs from "../../static/publications/C2021_IEEEAero_abs.gif";
import C2021_ASCE_abs from "../../static/publications/C2021_ASCE_abs.jpg";
import C2020_ISARC_abs from "../../static/publications/C2020_ISARC_abs.gif";
import C2016_IEEE_abs from "../../static/publications/C2016_IEEE_abs.jpg";
import C2021_ISTVS_abs from "../../static/publications/C2021_ISTVS_abs.gif";

/** @jsx jsx */
import { Styled, jsx} from "theme-ui";


class Publications extends React.Component {
  render() {

    const { avatar } = config;
    return (
      <Layout>
        <Helmet title={`${config.siteTitle} | Publications`} />

        {/* Journal Articles */}
        <div sx={{ display: 'grid', marginTop: `100px`}}>
          <Styled.h1 sx={{ textAlign: `left`, color: `text` }}>
            Selected Papers
          </Styled.h1>

          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `0px`}}>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `10px` }}>
              <img src={J2021_IEEERobo_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Subspace graph networks for real-time granular flow simulation with applications to machine-terrain interactions
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                Engineering Applications of Artificial Intelligence, 2024
                {/* <Styled.a sx={{ m: 1, color: `secondary`, backgroundColor: `background`, border: `solid 2px`, borderRadius: `5px`, fontSize:14, p: 1 }}>Under Review</Styled.a> */}
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "https://ca.linkedin.com/in/daniel-holz-51104a32" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Daniel Holz</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://www.sciencedirect.com/science/article/pii/S0952197624009230" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://arxiv.org/abs/2111.10206" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                  <Styled.a href = "https://github.com/haeriamin/Subspace-Graph-Physics" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Code
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>

          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `30px`}}>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `35px` }}>
          {/* <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `0px`}}>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `35px` }}> */}
              <img src={J2021_JFM_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Three-dimensionsal granular flow continuum modeling via material point method with hyperelastic nonlocal granular fluidity
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                Computer Methods in Engineering, 2022
                {/* <Styled.a sx={{ m: 1, color: `secondary`, backgroundColor: `background`, border: `solid 2px`, borderRadius: `5px`, fontSize:14, p: 1 }}>Under Review</Styled.a> */}
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://www.sciencedirect.com/science/article/pii/S0045782522001876" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://arxiv.org/abs/2111.01523" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                  <Styled.a href = "https://www.youtube.com/watch?v=_iNQWDR8nNA" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Video
                  </Styled.a>
                  <Styled.a href = "https://github.com/haeriamin/MPM-NGF" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Code
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>

          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `30px`}}>
          <div sx={{ textAlign: `left`, float: `left`, marginTop: `50px` }}>
              <img src={C2020_ISARC_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
              Optimal control of granular material
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
              ICRA, Japan, 2024 <Styled.a sx={{ m: 1, color: `secondary`, backgroundColor: `background`, border: `solid 2px`, borderRadius: `5px`, fontSize:14, p: 1 }}>Accepted</Styled.a>
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://scholar.google.com/citations?hl=en&user=cRHuDT4AAAAJ&view_op=list_works&sortby=pubdate" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Yuichiro Aoyama</a>, <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "https://sites.gatech.edu/acds/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Evangelos Theodorou</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://arxiv.org/abs/2302.03231" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>

        </div>


        {/* Conference Proceedings */}
        <div sx={{ display: 'grid', marginTop: `60px`}}>
          <Styled.h1 sx={{ textAlign: `left`, color: `text` }}>
            Others
          </Styled.h1>

          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `0px`}}>
            <div sx={{ textAlign: `left`, float: `left`, padding: `35px 0` }}>
              <img src={J2021_CM_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `0px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Gravity sensitivity of continuum numerical solvers for granular ﬂow modeling
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                Granular Matter, 2022
                {/* <Styled.a sx={{ m: 1, color: `secondary`, backgroundColor: `background`, border: `solid 2px`, borderRadius: `5px`, fontSize:14, p: 1 }}>Accepted</Styled.a> */}
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://link.springer.com/article/10.1007/s10035-022-01228-4" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1nv1ILEq7phBEexJ9Amozo-hHMjnHLDoM/view" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                  <Styled.a href = "https://github.com/haeriamin/MPM-NGF" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Code
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>

          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `0px`}}>
            <div sx={{ textAlign: `left`, float: `left`, padding: `20px 0` }}>
              <img src={C2021_IEEEAero_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Accurate and real-time simulation of rover wheel traction
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                IEEE Aerospace Conference, USA, 2021 <Styled.a sx={{ m: 1, color: `background`, backgroundColor: `secondary`, border: `solid 2px`, borderRadius: `5px`, fontSize:14, p: 1 }}>Best Track Paper Award</Styled.a>
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://ieeexplore.ieee.org/abstract/document/9438132" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1-2yKBqgC2zlB2wUBRoD277DrTvLrtXpd/view?usp=sharing" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1-Lto54ubebQxmkYXWqnm4YRFeiuuIk2n/view?usp=sharing" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Pres
                  </Styled.a>
                  {/* <Styled.a href = "/" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Video
                  </Styled.a> */}
                </Styled>
              </div>
            </div>
          </div>


          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `0px`}}>
            <div sx={{ textAlign: `left`, float: `left`, padding: `20px 0` }}>
              <img src={C2021_ISTVS_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Experimental exploration and real-time Simulation of the soil flow in blade-soil interactions
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                International Society for Terrain-Vehicle Systems (ISTVS), Canada, 2021
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "http://domtremblay.net/about.html" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Dominique Tremblay</a>, <a href = "https://ca.linkedin.com/in/daniel-holz-51104a32" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Daniel Holz</a>, <a href = "https://ca.linkedin.com/in/marekteichmann" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Marek Teichmann</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  {/* <Styled.a href = "https://ieeexplore.ieee.org/abstract/document/9438132" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1-2yKBqgC2zlB2wUBRoD277DrTvLrtXpd/view?usp=sharing" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1-Lto54ubebQxmkYXWqnm4YRFeiuuIk2n/view?usp=sharing" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Pres
                  </Styled.a> */}
                </Styled>
              </div>
            </div>
          </div>

          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `30px`}}>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `25px` }}>
              <img src={C2021_ASCE_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Granular flow modeling of robot-terrain interactions in reduced gravity
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                ASCE Earth and Space Conference, USA, 2021
              </Styled.p>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                IMA Conference on Granular Flows, University of Cambridge, UK, 2019
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://ascelibrary.org/doi/abs/10.1061/9780784483374.006" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1VuQl_kRQgTXK5e7LNrwQPjgaBtZn2tfy/view?usp=sharing" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Poster
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>


          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `30px`}}>
            <div sx={{ textAlign: `center`, float: `left`, padding: `60px 0` }}>
              <img src={C2020_ISARC_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                Efficient numerical methods for accurate modeling of soil cutting operations
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                International Symposium on Automation and Robotics in Construction, Japan, 2020
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>, <a href = "http://domtremblay.net/about.html" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Dominique Tremblay</a>, <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a>, <a href = "https://ca.linkedin.com/in/daniel-holz-51104a32" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Daniel Holz</a>, <a href = "https://ca.linkedin.com/in/marekteichmann" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Marek Teichmann</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://www.iaarc.org/publications/2020_proceedings_of_the_37th_isarc/efficient_numerical_methods_for_accurate_modeling_of_soil_cutting_operations.html" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1JoDAsKXv5AvvhITAjrokukMVUljLG2BI/view?usp=sharing" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Pres
                  </Styled.a>
                  <Styled.a href = "https://www.youtube.com/watch?v=BH52E_faKhE" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Video
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>


          <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '250px auto'], marginTop: `30px`}}>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `35px` }}>
              <img src={C2016_IEEE_abs} sx={{ width: 250, borderRadius: `5px` }}/>
            </div>
            <div sx={{ textAlign: `left`, float: `left`, marginTop: `20px` }}>
              <Styled.h2 sx={{ textAlign: `left`, color: `text` }}>
                A heuristic predictive LOS guidance law based on trajectory learning, ant colony optimization and tabu search
              </Styled.h2>
              <Styled.p sx={{ textAlign: `left`, color: `text` }}>
                IEEE Conf. on Control System, Computing and Engineering, Malaysia, 2016 <Styled.a sx={{ m: 1, color: `background`, backgroundColor: `secondary`, border: `solid 2px`, borderRadius: `5px`, fontSize:14, p: 1 }}>Best Paper Award</Styled.a>
              </Styled.p>
              <Styled.a sx={{ textAlign: `left`, color: `text` }}>
              <a href = "https://scholar.google.com/citations?user=vuUPY9kAAAAJ&hl=en" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Hadi Nobahari</a>, <a href = "https://www.aminhaeri.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amin Haeri</a>
              </Styled.a>
              <div sx={{ marginTop: `10px` }}>
                <Styled sx={{ display: 'grid', gridGap: 2, gridTemplateColumns: ['auto', 'auto auto'], flexWrap: `wrap`, justifyContent:`left` }}>
                  <Styled.a href = "https://ieeexplore.ieee.org/document/7893564" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Paper
                  </Styled.a>
                  <Styled.a href = "https://drive.google.com/file/d/1Cg03gdWAxSScCfxUyWU1BOnp0WUaG27d/view" target = "_blank"
                    sx={{ m: 0, backgroundColor: `background`, color: `primary`, border: `solid 2px`, borderRadius: `5px`, textDecoration: `none`, fontSize:16, p: 1, ":hover": { backgroundColor: `primary`, color: `background` }
                    }}>Preprint
                  </Styled.a>
                </Styled>
              </div>
            </div>
          </div>

        </div>
        

        <div sx={{ display: 'grid', marginTop: `60px`, marginBottom: `30px`}}>
          <AvatarLinks />
        </div>
      </Layout>

    );
  }
}

export default Publications;
